import '../scss/global.scss';
import '../bootstrap';
const $ = require('jquery');
require('bootstrap');

require('jquery-validation');
import './form-validation.js';
import './gdpr-cookie.js';

// Animacje
AOS.init();

$(window).scroll(function() {
      var amountScrolled = 300;
          if ( $(window).scrollTop() > (amountScrolled) ) {
              $('.back-to-top').fadeIn('slow');
          } else {
             $('.back-to-top').fadeOut('slow');
      }
});

$('.back-to-top').click(function(){
    $("html, body").animate({scrollTop: 0}, 100);
    return false;
});
$('.scroll').on('click', 'a', function(e){
    e.preventDefault();
    var hash = $(this).attr('href');
    var plus_px = -100;
        $('html, body').animate({
            scrollTop: $(hash).offset().top + plus_px
          }, 1500);
});


// Show / hide header search form when user scroll

$(window).scroll(function() {
    var amountScrolled = 500;
        if ( $(window).scrollTop() > (amountScrolled) ) {
           //$('.header-search-form').show();
        } else if($(window).scrollTop() == 0){
            //$('.header-search-form').hide();
        }
});

$(window).scroll(function(){
    var pageURL = $(location).attr("href");
    var offset = 0;
    console.log(pageURL);

    if ($(this).scrollTop() > 450) {
        if ( screen.width > 990 ){
            $(".navigation").addClass("header-fixed");
        }
    } else {
        $(".navigation").removeClass("header-fixed");
    }
});

// Show / hide product filter

$("#productFilterInput").on("keyup", function() {
    var value = $(this).val().toLowerCase();
    $(".category .product").filter(function() {
        $(this).toggle($(this).data('product').toLowerCase().indexOf(value) > -1);
        console.log($(this).data('product'));
    });
  });

$( "#showFilter" ).click(function(event) {

    event.preventDefault();

    $( "#filter-container" ).slideToggle( "slow", function() {
        $("#productFilterInput").val("");
        $(".category .product").css({ 'display' : 'block' });
        $('.product-filter-button').css('width', '80px');
        $(".category .product").attr('display-aos', 'fade-up');
    });
  });

/*
  $( "#wolny" ).click(function() {
    var value = "tak";
    $(".category .product").filter(function() {
        $(this).toggle($(this).data('wolny').toLowerCase().indexOf(value) > -1);
    });
  });

  $( "#wolny2" ).click(function() {
    var value = "nie";
    $(".category .product").filter(function() {
        $(this).toggle($(this).data('wolny').toLowerCase().indexOf(value) > -1);
    });
  });
*/



  $( "#navMobileIcon" ).click(function() {
    $('.nav-mobile').css('width', '250px');
  });

  $( "#navMobileIconClose" ).click(function() {
    $('.nav-mobile').css('width', '0px');
    //$('.nav-mobile').show('slide', {direction: 'left'}, 1400);
  });

  $( "#navMobileCategoryBtn" ).click(function() {
    $('.category-menu').slideToggle("slow");
  });


  $('.product-filter-button').on('click', function () {
    var value =  $(this).data('filtr');
    var status = "yes";

     $('.product-filter-button').css('width', '80px');
     $(this).css('width', '70px');

     $(".category .product").css({ 'display' : 'block' });
     $(".category .product").removeAttr('data-aos');

     //$(".category .product").attr('display-aos', 'fade-up');
    /*$(".category .product").filter(function() {
        $(this).toggle($(this).data(value).toLowerCase().indexOf(status) > -1);
    });*/
    $(".category .product").filter(function() {
      if($(this).data(value) != status){

          $(this).css('display', 'none');
      }
    });
  });

// Contact form




// Search form

$("#contactForm").on("submit", function (event) {
  event.preventDefault();
  var form = $(this);
  var actionUrl = '/email';

  $.ajax({
    type: "POST",
    url: actionUrl,
    data: form.serialize(), // serializes the form's elements.
    success: function (data) {
      $("#contactForm").append('<div style="background:#d4edda; color:#155724; margin-top:20px; padding:15px"> Dziękujemy za przesłanie zgłoszenia </div>');
      $("#contactForm").trigger("reset");
    }
  });

});

/***  Cookie init ***/

$.gdprcookie.init({});

/***  End Cookie init ***/

